


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseDataModule from '@/store/modules/base-data'
import MeetingModule from '@/store/modules/meeting'
import RoomInfo from '@/store/entities/room-info'
import OrganInfo from '@/store/entities/organ-info';

@Component
export default class EditRoom extends Vue {
  @Prop({ default: false }) isShow!: boolean;
  @Prop({ required: true, default: 0 }) id!: number;

  ruleForm: RoomInfo = new RoomInfo();
  saveLoading: boolean = false;

  get orgList() {
    return BaseDataModule.orgList;
  }

  get roomTypeList() {
    return BaseDataModule.roomTypeList;
  }

  async open() {
    if (this.id > 0) {
      await MeetingModule.getRoomById({ id: this.id }).then(res => {
        this.ruleForm = res;
      });
    }
  }

  async save() {
    (this.$refs.ruleForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.saveLoading = true;

        let o = this.orgList.find(x => x.id == this.ruleForm.organId) as OrganInfo;
        this.ruleForm.organName = o.fullName;

        await MeetingModule.editMeetingRoom(this.ruleForm).then((res) => {
          this.$message.success("操作成功");
          this.$emit("refreshData");
          this.close();
        }).finally(() => {
          this.saveLoading = false;
        });
      }
    });
  }

  resetForm() {
    (this.$refs.ruleForm as any).resetFields();
  }

  close() {
    this.resetForm();
    this.$emit("update:isShow", false);
  }
}
