import PagedFilterAndSortedRequest from '@/store/entities/page-request'

export default class RoomListQuery extends PagedFilterAndSortedRequest {
    /*会议室名称*/
    name: string = ""
    /*是否启用*/
    isActive!: boolean;
    /*所属机构*/
    organId!: number;
    /*会议室类型*/
    roomType!: number;
    /*视频会议*/
    isSupportVideo!: boolean;
}