























































































import { Component, Vue } from "vue-property-decorator";
import RoomListQuery from "@/store/entities/room-list-query";
import MeetingModule from "@/store/modules/meeting";
import BaseDataModule from "@/store/modules/base-data";
import EditRoom from '@/views/meeting-room/EditRoom.vue'

@Component({ components: { EditRoom } })
export default class RoomList extends Vue {
  query: RoomListQuery = new RoomListQuery();
  roomId: number = 0;

  dataLoading: boolean = true;
  isShowEdit: boolean = false;

  list: Array<any> = [];
  totalCount: number = 0;
  currentPage: number = MeetingModule.currentPage;
  pageSize: number = MeetingModule.pageSize;

  get orgList() {
    return BaseDataModule.orgList;
  }

  get roomTypeList() {
    return BaseDataModule.roomTypeList;
  }

  get isAdmin() {
    return BaseDataModule.currentUser.isAdmin;
  }

  editRoomView(id: number) {
    this.roomId = id;
    this.isShowEdit = true;
  }

  addRoomView() {
    this.roomId = 0;
    this.isShowEdit = true;
  }

  resetSearch() {
    (this.$refs.searchRoom as any).resetFields();
    this.searchData();
  }

  async toggleActive(id: number, isActive: boolean) {
    await MeetingModule.toggleRoomActive({ id, isActive })
      .then(async res => { await this.getData(); });
  }

  async searchData() {
    this.currentPage = 1;
    await this.getData();
  }

  async sortChange(sort: any) {
    if (!sort.order) {
      this.query.sorting = ""
    } else if (!sort.column.sortBy) {
      this.query.sorting = `${sort.prop} ${sort.order}`.replace("ending", "");
    } else {
      this.query.sorting = `${sort.column.sortBy} ${sort.order}`.replace("ending", "");
    }
    await this.getData();
  }

  async pageChange(page: number) {
    this.currentPage = page;
    await this.getData();
  }

  async sizeChange(count: number) {
    this.pageSize = count;
    await this.getData();
  }

  async getData() {
    this.dataLoading = true;
    this.query.maxResultCount = this.pageSize;
    this.query.skipCount = (this.currentPage - 1) * this.pageSize;

    await MeetingModule.getAllRoomByPage(this.query).then(res => {
      this.list = res.items;
      this.totalCount = res.totalCount;
    }).finally(() => {
      setTimeout(() => {
        this.dataLoading = false;
      }, 300);
    });
  }

  async mounted() {
    let userId = this.$route.query.id;
    if (userId) await BaseDataModule.getEmployeeById({ employeeId: userId });

    this.getData();
    await BaseDataModule.getOrganizationList();
    await BaseDataModule.getRoomType();
  }

}
