export default class RoomInfo {
    id: number = 0;
    name: string = "";
    roomDesc: string = "";
    isActive: boolean = false;
    equipment: string = "";
    roomType!: number;
    idHg: number = 0;
    organId!: number;
    organName: string = "";
    isSupportVideo: boolean = false;
    sort: number = 0;
    seatNum: number = 0;
    roomTypeDes: string = "";
}